<template>
  <eagle-map />
</template>

<script>
import EagleMap from "../components/EagleMap";

export default {
  name: "Home",

  components: {
    EagleMap,
  },
};
</script>
