<template>
  <v-container fluid class="full">
    <v-dialog v-model="dataDialog" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Historical Bald Eagle Nests
        </v-card-title>
        <v-card-text>
          <p><v-spacer></v-spacer></p>
          <p>
            This map displays bald eagle nests documented by USFWS and others.
          </p>

          <p>
            The Historical Bald Eagle Nest map is not intended to take the place
            of a survey to determine the presence/absence, status, or exact
            location of a current eagle nest. Current eagle nests and potential
            habitat may or may not overlap with the nesting areas shown.
          </p>

          <p>
            By clicking "Accept" below, you have read the above statement and
            understand the limitations of the data.
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="acceptDataDialog"> Accept </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="fill">
      <v-col cols="10">
        <div ref="map-root" style="width: 100%; height: 100%"></div>
      </v-col>
      <v-col cols="2">
        <div v-if="featureAttributes">
          <b>{{ featureAttributes[0].nest_id }}</b
          ><br />
          {{ featureAttributes[0].build_species }},
          {{ featureAttributes[0].tree_species }}
          <br />
          ({{ featureAttributes[0].latitude_nad83 | toFixed(4) }},
          {{ featureAttributes[0].longitude_nad83 | toFixed(4) }})
          <ul>
            <li v-bind:key="f.visit_date" v-for="f in featureAttributes">
              {{ f.visit_date }}
              <ul>
                <li v-if="f.use_species">Use: {{ f.use_species }}</li>
                <li v-if="f.nest_status">Status: {{ f.nest_status }}</li>
              </ul>
            </li>
          </ul>
        </div>
        <div v-else>Hover over a nest to view attributes</div>
      </v-col>
    </v-row>
    <v-row class="bottom">
      <v-col>
        <v-btn color="accent" @click="getFeatures">
          {{ getFeaturesBtnLabel }}
          <v-progress-circular
            v-if="loadingData"
            indeterminate
            color="primary"
            :size="30"
            class="ml-3"
          ></v-progress-circular>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Map from "ol/Map";
import View from "ol/View";
import LayerGroup from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import XYZ from "ol/source/XYZ";
import { Circle, Stroke, Fill, Style } from "ol/style";
import VectorSource from "ol/source/Vector";
import { Vector as VectorLayer } from "ol/layer";
import GeoJSON from "ol/format/GeoJSON";
import { bbox } from "ol/loadingstrategy";
import proj4 from "proj4";
import { transform } from "ol/proj";
import { register } from "ol/proj/proj4";

import LayerSwitcher from "ol-layerswitcher";

import "ol/ol.css";
import "ol-layerswitcher/dist/ol-layerswitcher.css";

import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;

proj4.defs(
  "EPSG:3338",
  "+proj=aea +lat_1=55 +lat_2=65 +lat_0=50 +lon_0=-154 +x_0=0 +y_0=0 +datum=NAD83 +units=m +towgs84=-0.9956,1.9013,0.5215,0.025915,0.009426,0.011599,-0.00062 +no_defs"
);
register(proj4);

export default {
  name: "EagleMap",
  components: {},
  props: {},
  data: () => ({
    dataDialog: false,
    loadingData: false,
    map: null,
    layerSwitcher: null,
    eagleNests: null,
    selectedFeature: null,
    featureAttributes: null,
    pointStyle: new Circle({
      radius: 5,
      fill: new Fill({ color: "rgba(128, 128, 196, 0.7)" }),
      stroke: new Stroke({ color: "rgba(64, 64, 128, 1.0)", width: 2 }),
    }),
    highlightStyle: new Style({
      image: new Circle({
        radius: 5,
        fill: new Fill({ color: "rgba(255, 64, 64, 0.7)" }),
        stroke: new Stroke({ color: "rgba(255, 64, 64, 1.0)", width: 2 }),
      }),
    }),
  }),
  filters: {
    toFixed: function (v, f) {
      return parseFloat(v).toFixed(f);
    },
  },
  computed: {
    extent: function () {
      return this.map.getView().calculateExtent(this.map.getSize());
    },
    visibleFeatures: function () {
      let features = [];
      if (this.eagleNests) {
        this.eagleNests.forEachFeatureInExtent(this.extent, function (feature) {
          let coordinates = feature.getGeometry().getCoordinates();
          let coordinatesWGS84 = transform(
            coordinates,
            "EPSG:3857",
            "EPSG:4326"
          );
          let coordinates3338 = transform(
            coordinates,
            "EPSG:3857",
            "EPSG:3338"
          );
          let featureObject = {
            nest_id: feature.get("nest_id"),
            build_species: feature.get("build_species"),
            latitude: coordinatesWGS84[1],
            longitude: coordinatesWGS84[0],
            x_3338: coordinates3338[0],
            y_3338: coordinates3338[1],
          };
          features.push(featureObject);
        });
      }

      // only unique nests
      // one-liner:
      return features.filter(
        (e, i) => features.findIndex((a) => a.nest_id === e.nest_id) === i
      );
      // theoretically faster:
      // let unique = {};
      // for (let f of features) {
      //   unique[f.nest_id] = f;
      // }
      // let uniqueFeatures = [];
      // for (let k in unique) {
      //   uniqueFeatures.push(unique[k]);
      // }
      // return uniqueFeatures;
    },
    getFeaturesBtnLabel: function () {
      let featureCount = this.visibleFeatures.length;
      if (featureCount === 1) {
        return `Get ${featureCount} feature`;
      } else {
        return `Get ${featureCount} features`;
      }
    },
  },
  mounted: function () {
    const nowEpoch = new Date().getTime();
    const dataDialogStr = localStorage.getItem("dataDialog");
    if (dataDialogStr) {
      const dataDialogEpoch = parseInt(dataDialogStr);
      if (nowEpoch > dataDialogEpoch) {
        this.dataDialog = true;
      }
    } else {
      this.dataDialog = true;
    }

    this.eagleNests = new VectorSource({
      format: new GeoJSON(),
      url: function (extent) {
        let url = new URL("https://eagle.abrinc.com/cgi-bin/mapserv");
        let parameters = {
          map: "/var/www/gis/eagle.map",
          service: "WFS",
          version: "1.1.0",
          request: "GetFeature",
          typeName: "eagle",
          srsName: "EPSG:3857",
          bbox: extent.join(","),
          outputFormat: "geojson",
        };
        url.search = new URLSearchParams(parameters).toString();

        return url.href;
      },
      strategy: bbox,
    });
    this.map = new Map({
      target: this.$refs["map-root"],
      layers: [
        new LayerGroup({
          title: "Base maps",
          layers: [
            new TileLayer({
              title: "ESRI World Imagery",
              type: "base",
              visible: false,
              source: new XYZ({
                url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                attributions:
                  'Tiles © <a href="https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/">ArcGIS</a>',
              }),
            }),
            new TileLayer({
              title: "DNR Satellite",
              type: "base",
              visible: false,
              source: new XYZ({
                url: "https://geoportal.alaska.gov/arcgis/rest/services/ahri_2020_rgb_cache/MapServer/tile/{z}/{y}/{x}",
                attributions:
                  "Maxar Products. Alaska High Resolution Imagery © 2020 Maxar Technologies Inc.",
              }),
            }),
            new TileLayer({
              title: "OpenStreetMap",
              type: "base",
              visible: false,
              source: new OSM(),
            }),
            new TileLayer({
              title: "USGS Topo",
              type: "base",
              visible: true,
              source: new XYZ({
                url: "https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}",
                attributions:
                  "Tiles © USGS The National Map: National Boundaries Dataset, 3DEP Elevation Program, Geographic Names Information System, National Hydrography Dataset, National Land Cover Database, National Structures Dataset, and National Transportation Dataset; USGS Global Ecosystems; U.S. Census Bureau TIGER/Line data; USFS Road Data; Natural Earth Data; U.S. Department of State Humanitarian Information Unit; and NOAA National Centers for Environmental Information, U.S. Coastal Relief Model. Data refreshed August, 2021.",
              }),
            }),
          ],
        }),
        new LayerGroup({
          title: "Data Layers",
          layers: [
            new VectorLayer({
              title: "Eagle nests",
              visible: true,
              source: this.eagleNests,
              style: new Style({
                image: this.pointStyle,
              }),
            }),
          ],
        }),
      ],

      view: new View({
        zoom: 7,
        center: [-16400000, 9110000],
        constrainResolution: true,
      }),
    });

    this.layerSwitcher = new LayerSwitcher();
    this.map.addControl(this.layerSwitcher);

    this.map.on(
      "pointermove",
      function (e) {
        if (this.selectedFeature !== null) {
          this.selectedFeature.setStyle(undefined);
          this.selectedFeature = null;
        }

        this.map.forEachFeatureAtPixel(
          e.pixel,
          function (f) {
            this.selectedFeature = f;
            f.setStyle(this.highlightStyle);
            return true;
          }.bind(this)
        );

        if (this.selectedFeature) {
          if (
            !this.featureAttributes ||
            this.featureAttributes[0]["nest_id"] !==
              this.selectedFeature.get("nest_id")
          ) {
            this.getNestData(this.selectedFeature.get("nest_id"));
          }
        } else {
          this.featureAttributes = null;
        }
      }.bind(this)
    );
  },
  methods: {
    getFeatures: async function () {
      const date = new Date().toISOString();
      this.loadingData = true;
      const promises = await this.visibleFeatures.map(async (feature) => {
        return await axios.get(
          `nest_location_visit_architecture/nest_id/${feature.nest_id}/`
        );
      });
      const resolved = await Promise.all(promises);
      const nestLocationVisitArchitecture = resolved
        .map(
          (resource) =>
            resource.data.sort((a, b) =>
              ("" + b.visit_date).localeCompare(a.visit_date)
            )[0]
        )
        .flat();
      const select = [
        "nest_id",
        "build_species",
        "visit_date",
        "use_species",
        "nest_status",
        "tree_species",
        "latitude_nad83",
        "longitude_nad83",
      ];
      let csv = this.$papa.unparse(nestLocationVisitArchitecture, {
        columns: select,
      });
      this.loadingData = false;
      this.$papa.download(csv, `eagle_nests_${date}`);
    },
    getNestData: async function (nest_id) {
      await axios
        .get(`nest_location_visit_architecture/nest_id/${nest_id}/`)
        .then((response) => {
          this.featureAttributes = response.data.sort((a, b) =>
            ("" + b.visit_date).localeCompare(a.visit_date)
          );
        });
    },
    acceptDataDialog: function () {
      this.dataDialog = false;
      const nowEpoch = new Date().getTime();
      localStorage.setItem("dataDialog", nowEpoch + 24 * 60 * 60 * 1000);
    },
  },
};
</script>

<style>
.full {
  display: flex;
  flex-flow: column;
  height: 100%;
}
.fill {
  flex: 1 1 auto;
}
.bottom {
  flex: 0 1 0 !important;
}
.layer-switcher {
  top: 0.5em;
  right: 0.5em;
}
.layer-switcher .panel {
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(64, 64, 64, 0.7);
}
.layer-switcher ul {
  list-style: none;
  margin: 1em 0.4em;
  padding-left: 0;
}
.layer-switcher ul ul {
  padding-left: 0.6em;
  margin: 0.1em 0 0 0;
}
</style>
